import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";

import chruchImg from "../../images/about/image13.jpg";
import travancoreImg from "../../images/about/image15.jpg";
import missionaryImg from "../../images/about/image14.png";

const CSIMKD = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="CSI MKD" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">
                CSI Madhya Kerala Diocese at a Glance
              </h3>
              <h6>Introduction</h6>
              <p>
                The Madhya Kerala Diocese is one of the twenty-four dioceses of
                the Church of South India (commonly referred to as CSI)
                (successor of the Church of England) covering the central part
                of Kerala. When the Church of South India was formed on 27
                September 1947 the diocese was called the Diocese of Central
                Travancore. It was a part of the erstwhile Anglican Diocese of
                Travancore and Cochin founded in 1879. The Diocese was later
                renamed Diocese of Madhya Kerala.
              </p>
              <div className="about-image-wrapper about-image-wrapper_medium">
                <img src={chruchImg} alt="church"></img>
              </div>
              <h3 className="about-subtitle">History</h3>
              <p>
                Kerala, the State, clothed in nature’s finery, protected by the
                Rocky Mountains in the East and washed by the waves of the
                Arabian Sea in the West, has been blessed with Christianity from
                the first century. Tradition has it that it was St. Thomas, the
                Apostle of Jesus who brought Christianity to Kerala. The Ancient
                Syrian Church of Malabar had links with Christian centres in
                West Asia. The winds of the Reformation which rocked Europe in
                the 16th century swept India as well with the coming of the
                missionaries of the Church Missionary Society, the London
                Missionary Society and the Basel Mission.
              </p>
              <p>
                The Church Missionary Society (CMS), was a society organized by
                some evangelicals of the Church of England on April 12th 1799 to
                help the propagation of the Gospel in Africa and in the East.
                The CMS was, of course, the child of ‘Evangelical Anglicanism’
                and its original name was ‘The Society for Missions in Africa
                and the East’. After a few years, the title “The Church
                Missionary Society” was formally adopted.
              </p>
              <h6>British Connection with Travancore</h6>
              <p>
                The origin of Travancore’s connection with the British goes back
                to 1685 when the English East India Company established a
                factory at Anjengo in Travancore by obtaining land from the
                Attingal Rani (the Queen of Attingal). The English established
                the factory mainly with a view to breaking up the Dutch monopoly
                in those parts. Thus a cordial relationship between the East
                India Company and Travancore was developed.
              </p>
              <div className="about-image-wrapper about-image-wrapper_medium">
                <img src={travancoreImg} className="w-100" alt="church"></img>
              </div>
              <p>
                In the second half of the 18th century. the fear of invasion
                from Haider Ali and Tipu Sultan compelled Travancore to depend
                for her safety on the English East India Company. In November
                1795, a treaty of perpetual friendship and alliance was signed
                between the Rajah of Travancore and the East India Company. The
                treaty was again modified in 1805, which established British
                paramountcy over Travancore. As a result of these treaties, the
                British Residents were henceforth to represent Great Britain at
                the Court of Travancore. The first two residents were Col Colin
                Macaulay (1800-1810) and Col John Munro (1810-1819), who were
                protestant Christians of strong convictions; interested in the
                affairs of Jacobite Syrians.
              </p>
              <h6>The CMS in Travancore</h6>
              <p>
                The origin of the work of the C.M.S. in Travancore can be traced
                to the Rev R H Kerr and the Rev Claudius Buchanan, who paid
                visits to the Malabar Syrians in 1806, during the episcopate of
                Mar Dionysius. It was Lord William Bentinck, who sent Dr Kerr to
                Travancore for the purpose of investigating the state of the
                native church. E M Philip tells us that, “he (Kerr) expressed to
                the Metropolitan of the Syrian Church a hope that one day a
                union might take place between the Syrian and the Anglican
                Church and that he seemed pleased at the suggestion.”
              </p>
              <p>
                The next friendly Anglican visitor was Dr Buchanan, who evinced
                a keen desire that the Syrian Church and the Church of England
                should be brought closer together. His speech at the CMS
                Anniversary in 1809 and his famous book, “Christian Researches
                in Asia”, drew the attention of the English people to the Syrian
                Christians of Travancore.
              </p>
              <p>
                According to W J Richards, a C M S Missionary in Travancore, in
                the beginning of 19th century the religious and social
                conditions of the Syrian Christians were pathetic. The people
                were steeped in ignorance and superstitions. The Jacobite Syrian
                Church was also at this time at a very low spiritual level. This
                is clear in the words of the Syrian Metropolitan, when he had an
                interview with Dr Buchanan in which he says, “you have come to
                visit a declining church.”
              </p>
              <h6>The Mission of Help to the Syrians</h6>
              <p>
                The CMS Mission of Help to the Jacobite Syrians of Kerala was
                started in the year 1816, of which the initiative came from Col
                Munro, the then British Resident of Travancore. There were two
                main purposes behind the Mission of Help to the Syrians. First
                of all, through the work of the C M S Missionaries among the
                Syrians, to effect the renovation of their Church and to raise
                them from their degradation.
              </p>
              <p>
                Secondly, the British Resident as well as the missionaries hoped
                that “a strong and friendly Christian Community will be a
                support for the British power in Malabar”. Rev Thomas Norton was
                the first missionary who came to Travancore in this connection.
                He was soon followed by Benjamin Bailey (1816), Joseph Fenn
                (1818) and Henry Baker (Sr) (1819) who are popularly known as
                the "Kottayam Trio".
              </p>
              <p>
                These three concentrated their work among the Syrians, whereas
                the pioneer missionary, Norton focused his work among the
                outcastes in Alleppey.
              </p>
              <p>
                The work of the missionaries among the Jacobite Syrians was
                mainly on the education field. Fenn took charge of the college
                for training the younger clergy; Bailey devoted himself chiefly
                to literary and translation work and the press, while Baker took
                charge of the parish schools up and down the land. Though the
                relationship between the missionaries and the Jacobite Syrians
                went on well without any problems, in the beginning, it did not
                last long. The change of leadership in the Jacobite Syrian
                Community as well as the change of missionaries caused many
                problems in the relationship. During the second half of the
                Mission of Help, the pioneer missionaries went on furlough.
              </p>
              <p>
                While they were away new men came on the scene, Joseph Peet
                (1833-1865) and W J Wood Cock (1834-1837). The young
                missionaries were rather impatient about the slow progress being
                made and were sometimes rash in their actions. The visits of the
                Rev J Tucker, Secretary of the C M S Corresponding Committee at
                Madras, and Bishop Wilson, the Anglican Bishop of Calcutta did
                not heal the wound, these two being uncompromising evangelists.
                This was followed by a Synod of the Syrian Christians at
                Mavelikkara on 16th January 1836, in which the Jacobite Syrian
                Community under Mar Dionysios IV, the then Malankara
                Metropolitan decided to break all their relationships with the
                Church of England. With this, we see an early death of the
                twenty-year-old C M S Mission of Help to the Syrian Church of
                Travancore.
              </p>
              <p>
                Was the Mission of Help a failure? An eminent Hindu, Diwan
                Bahadur Nagamiah says in the Travancore State Manual, “Although
                the Syrians headed by their Bishop had thus formally parted
                company with the Church Missionary Society, the teaching of the
                missionaries for more than twenty years had not been without
                result, and there was among the Syrians a party who was
                influenced by that teaching.”
              </p>
              <h6>Missionaries Turn to the Masses</h6>
              <p>
                The dissolution of the contract between the C M S and the Syrian
                Metropolitan after 20 years of beneficial work was no doubt
                says, C M Agur “a great disappointment” With the snapping of
                ties, the missionaries directed their attention to the despised
                and the downtrodden Ezhavas, Malayarayans (Hill Arrians), and
                the outcastes of Central Travancore.
              </p>

              <p>
                Due to the impact of the work of the C M S among the Syrian
                Christians, soon after the separation with them, several Syrian
                Christians who were attracted towards the reformation joined the
                Anglican Church. In certain cases, the whole Syrian parishes
                joined with the missionaries. Therefore, the missionaries began
                to serve them as parish priests too.
              </p>

              <p>
                In 1840, Bishop Spencer of Madras, who succeeded Bishop Daniel
                Corrie after his death in 1837, made his first episcopal visit
                to Malabar soon after the Archbishop of Canterbury had put the
                congregations of Travancore under the Episcopal oversight of the
                Bishop of Madras. Thus the Anglican Church was fully established
                in Travancore in 1840. By the 1840s missionaries started
                systematic evangelism among the non-Christians, especially those
                of the lower classes. In 1848 Baker reported that he baptized
                thirty-five individuals. He again speaks: “They have been
                Chogans. Two I had rescued from slavery very accidentally.” In
                1850, Rev J Hawksworth wrote, "The visible success of this
                mission during the past half-year has been almost exclusively
                among the 'heathen'".
              </p>

              <p>
                Early in 1876, the Society began negotiations with the Secretary
                of State for India so that a new diocese can be formed under the
                Jerusalem Bishopric Act for the Church of England in the Native
                States of Travancore and Cochin. The Diocese of Travancore and
                Cochin (in the Church of India, Burma and Ceylon) was erected
                with its See at Kottayam in 1879 and John Speechly, then
                Principal of the Cambridge Nicholson Institute (CMS theological
                college, Kottayam), was announced as the first Bishop of
                Travancore and Cochin. Speechly was consecrated a bishop on 25
                July 1879 at St Paul's Cathedral; he arrived at Kottayam on 27
                January 1880.
              </p>

              <p>
                In 1888, Speechly left for England and was unable to return, and
                Noel Hodges (1890–1904), a CMS Missionary from Ceylon followed
                him as diocesan bishop. He was installed in the Pro-Cathedral,
                Kottayam in November 1890. During the episcopate of Hodges, the
                first missionary enterprise of the diocese was organised as its
                Home Mission, was started at Adoor in 1903. Hodges retired in
                1905 and was succeeded by Hope Gill (1905–1925), who was
                consecrated in Westminister Abbey and arrived in Kottayam in
                1906. The full creation of Diocesan structures which began in
                1879 was fulfilled in 1920, when Gill constituted, "Travancore
                and Cochin Diocesan Council" to assist in the management of the
                temporal affairs and financial business of the church.
              </p>
              <div className="about-image-wrapper about-image-wrapper_medium">
                <img src={missionaryImg} className="w-100" alt="church"></img>
              </div>
              <p>
                On 27 September 1947, the four southernmost dioceses of CIBC
                united with other churches to form the Church of South India.
                The presiding bishop at the CSI's inauguration was C. K. Jacob,
                Bishop of Travancore and Cochin who became Bishop in Central
                Travancore; his diocese's territory was reduced and renamed the
                Diocese of Central Travancore. Subsequently, it has been renamed
                the Diocese of Madhya Kerala after that state was formed in
                1956- Madhya is Malayalam/Hindi for central.
              </p>
              <h3 className="about-subtitle mt-5">Administration</h3>
              <p>
                The Diocese has a Diocesan Council which governs the diocese.
                All the clergy of the diocese and elected laymen from the local
                congregations are members of the Diocesan Council.
              </p>

              <p>
                The diocese is administratively divided into two zones (North
                Zone and South Zone), each headed by a District Minister. And it
                is further divided into twelve District Councils. The bishop of
                the diocese is assisted by the Executive Committee, formed by an
                election from the Diocesan Council.
              </p>

              <p>
                The Diocesan Headquarters is at Kottayam, Kerala. The Bishop's
                House and a Retreat Centre is on the campus of the Headquarters.
              </p>

              <p>
                The Diocese also publishes an official newsletter named
                Njananikshepam (The Treasury of Knowledge) every month. The
                newsletter was published first in the year 1848 from C.M.S.
                press, Kottayam.
              </p>

              <h6>Bishop</h6>
              <p>Rt. Rev. Dr Malayil Sabu Koshy Cherian</p>

              <p>Anglican Bishops of Travancore and Cochin</p>
              <p>Rt. Rev. John Speechly (1879–1889)</p>
              <p>Rt. Rev. Noel Hodges (1890–1904)</p>
              <p>Rt. Rev. Hope Gill (1905–1924)</p>
              <p>Rt. Rev. Edward Moore (1925–1937)</p>
              <p>Rt. Rev. Bernard Corfield (1938–1944)</p>
              <p>Rt. Rev. Cherakarottu Korula Jacob (1945–1947)</p>

              <p>CSI Bishops in Central Travancore/Madhya Kerala</p>
              <p>Rt. Rev. C. K. Jacob (1947–1957)</p>
              <p>Rt. Rev. M. M. John (1958–1974)</p>
              <p>
                Rt. Rev. T. S. Joseph, Assistant Bishop (consecrated 1 July
                1967)
              </p>
              <p>Rt. Rev. T.S. Joseph (1974–1981) installed 27 December</p>
              <p>Rt. Rev. M. C. Mani (1981–1993) consecrated 8 February</p>
              <p>Rt. Rev. Dr Sam Mathew (1993–2001) consecrated 1 September</p>
              <p>Rt. Rev. Thomas Samuel (2001–2011)</p>
              <p>Rt. Rev. Thomas K. Oommen (2011–2020)</p>
              <p>Rt. Rev. Dr Malayil Sabu Koshy Cherian (2021-Till date)</p>

              <h6>District Councils</h6>
              <p>
                The Diocese is divided into 12 District Councils. Each Council
                has a chairman presbyter.
              </p>
              <p>
                1 Adoor: Adoor, Kadampanad, Kollam, Kulathupuzha, S.Kunnida,
                Munroethuruthu, Puthuval, Thazhathumon, Thiruvananthapuram
              </p>
              <p>
                2 Elanthoor: Cheenerkara, Elanthoor, Kallely, Kidangannoor,
                Kuzhikkala, Mallassery, Nallanikunnu, Omalloor, Pathananthitta,
                Punnackad&nbsp;&nbsp;
              </p>
              <p>
                3 Ettumanoor: Ettumanoor, Kattampakal, Koothattukulam,
                Muttuchira, Njeezhoor, Ottiankurnnu, Piravam, Vaikom,
                Varikkamkkunnu, Velloor
              </p>
              <p>
                4 Kodukulanji: Kodukulanji, Angadickal, Chengannur, Karode,
                Kozhuvalloor, Kollakadavu, Cheruvalloor
              </p>
              <p>
                5 Kottayam: Arpookara, Ascension Kottayam, Cathedral Kottayam,
                Central Kumarakom, Erikadu, Kothala, Kumaranelloor, Machukadu,
                Manganam, Manarcadu, Muttambalam, Olassa, Pampady,
                Thiruvanchoor, Vadavathoor&nbsp;
              </p>
              <p>
                6 Kumplampoika: Ayroor, Chittar, Ennooramvayal (Vechoochira),
                Karikkattor, Kumplampoika, Neerettucavu, Pullikkallu Ranni,
                Vayalathala&nbsp;&nbsp;
              </p>
              <p>
                7 Mallappally: Ezhumattoor, Kaipatta, Keezhvaipur,
                Koothrappally, Kottanad, Mallappally, Mammood, Narakathani,
                Nedungadappally, Pariyaram&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                8 Mavelikara: Mavelikara, Kallumala, Cherukunnam, Kattanam,
                Kappil, Bharanikkavu, South Puthuppally, Alumpedika, Njakkanal,
                Chamavila, Kayamkulam, Kanneetti, Mynagappally,
                Monkuzhy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                9 Mundakkayam: Mundakkayam, Thidanad, Vazhoor, Kanam, Edakunnam,
                Ponkunnam, Karinilam
              </p>
              <p>
                10 Pallom: Pallom, Panimattom, Velluthuruthy, Kavalam,
                Changanacherry, Kollad, Alappuzha, Karumadi, Njaliakuzhy,
                Mooledom, Thuruthy, Muhamma, Nalunnakkal,
                Poovanthuruthu&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                11 Punnavely: Punnavely, Neelampara, Chelakompu, Mundathanam,
                Kangazha, East Meenadom,
                Mulekkunnu&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p>
                12 Thiruvalla: Tholassery, Kaviyoor, Perumthuruthy,
                Valanjavattom, Thalavady, Mundiappally, Kunnamthanam, Kuttoor,
                Poovathoor, Kumbanad, Warikkadu
              </p>
              <h3 className="about-subtitle mt-5">
                Diocesan Sunday School-A Brief History
              </h3>
              <p>
                The efforts of Robert Rakes of England to teach the children
                Bible stories in a systematic way resulted in the formation of
                an organization called the Sunday School in 1780. Within a few
                years, it had spread to many countries and by 1790 about 3 lakh
                students had become Sunday school students. The world Sunday
                school union was formed in 1808 and the India Sunday Schools
                union started functioning at Allahabad in 1876.
              </p>

              <p>
                The early CMS missionaries who came to Kerala began teaching The
                Bible to children also, and therefore Sunday schools were
                already functioning in many of the Churches established by these
                missionaries. Systematic Sunday school work is understood to
                have been started, in 1880, at Mallappally, by Arch Deacon
                Oommen Mammen who got in touch with the India Sunday schools
                union roughly around that time. Hence 1880 is considered to be
                the year of starting formal Sunday schools in our Diocese.
              </p>

              <p>
                It took some more years to get this movement well organized in
                the Diocese. Rev. K.O. Philip was appointed as the first General
                Secretary of the Diocesan Sunday school in 1924 and Rev. M M
                John (later became the Bishop of Madhya Kerala Diocese) took up
                this responsibility in 1934. ‘Balajanaperunal’, the birthday of
                the Diocesan Sunday school, was started in 1945. Regular
                examinations for the children and teachers according to the
                syllabus of the All India Sunday Schools union were held from
                1927 onwards. The mission support by the Sunday school children
                for the work in Nadikooda, one of the villages under the Parkal
                Mission, started in 1938. Though VBS (Vacation Bible School)
                ministry was taken up formally and systematically by the
                Diocesan Sunday school only in 2003, the first VBS in the
                Diocese was conducted as early as 1954.
              </p>

              <p>
                Training camps for teachers of the Sunday schools are held by
                the Diocese from the 1920s and the annual Sunday school camp at
                the Diocesan level was started in 1982. Competitions in various
                disciplines at the Diocesan level are conducted since 1983.
                ‘Njanasandesamâm, the quarterly publication of the Sunday school
                was commenced in 1998. A correspondence course for Bible study
                called&nbsp;‘Vissuasa Prayanam’&nbsp;was started in 2003.
                Mission Sunday is celebrated since 2001 and the collection of
                that day is used for the support of a Home Mission field of the
                Diocese. Junior Missionary Association JMA, started functioning
                in 1986 in order to encourage children for missionary work. It
                was defunct for a few years but was revived again in 2005.
              </p>
              <h3 className="about-subtitle mt-5">Diocesan Youth Movement</h3>
              <p>
                India became independent on August 15, 1947, and one month
                later, the formation of the Church of South India took place,
                its inauguration on 27th September 1947 was a historic event -
                an Indian Church in an Indian - ruled country. The formation of
                the Church of South India was one of the remarkable events in
                the History of Christianity.
              </p>
              <p>
                The CSI Madhya Kerala Diocesan Youth Movement is the first
                Christian Youth Movement in Asia. The earliest form of the
                Diocesan Youth Movement was the ‘Annual Anglican Youth League’
                which was started by Bishop Charles Hope Gill. In 1916, during
                the time of the centenary celebrations of the Church Missionary
                Society Activities in Travancore-Cochin, the missionaries felt
                the need of utilizing the creative and physical potentials of
                the youth for the betterment of the Church and the society. For
                this reason, to unite the Youth, the missionaries started the
                ‘Annual Anglican Youth Conference’. The very aim of the Annual
                Conference was to invite the youth to serve the church and the
                society through the love of Jesus Christ and thus lead them to
                better dedication and commitment. It was in the English language
                that they arranged the sessions of the conference. From 1932
                onwards, an annual conference was started in Malayalam for the
                non-English speaking people.
              </p>
              <p>
                In 1942, the organisation became very strong which was known as
                ‘Youth League’ and started district level conferences in all the
                10 ecclesiastical districts of the diocese. In 1947, a full-time
                general secretary (Mr John Ramakrishna Pillai) was appointed for
                the smooth functioning of the organisation. Till this time there
                were no women participating in the conferences. In 1959, the
                ‘Yuvatheesamajam’ which was an integral part of the Women’s
                Fellowship merged with Youth League. In 1978, the name of the
                organisation was changed from’ Youth League’ to ‘Youth
                Movement’.
              </p>
              <h3 className="about-subtitle mt-5">
                Diocesan Women’s Fellowship
              </h3>
              <p>
                Women’s Fellowship of Madhya Kerala Diocese is one of the
                effective bodies of the diocese with a strong structure, which
                works towards the empowerment of women, both in the spiritual
                and secular fields. Geographically the diocese occupies the
                central part of Kerala. Madhya Kerala Diocese came into
                existence on 27 September 1947 when the Church of South India
                was formed.
              </p>

              <p>
                Mrs Gill started the ‘Mother’s Union’ in 1909 on St. Mary’s day.
                When the ‘Women’s Fellowship’ of CSI was formed in 1948 the
                ‘Mother’s Union’ merged into it.
              </p>

              <p>
                Under the auspices of the Women’s Fellowship, several programmes
                and projects are done. One such programme is Divyabodhini. It is
                a comprehensive theological educational programme. Classes are
                held in four centres. The classes primarily focus on the
                empowerment of their physical, mental and spiritual abilities,
                so that those dormant skills can be awakened and utilised for
                the upliftment of their families, churches and communities at
                large. The diocese comprises 123 pastorates and many outstations
                and 12 districts with Women's Fellowship district secretaries.
                These secretaries look after the functioning WF works in their
                respective areas. WF members from each district meet in one
                place in the district four times a year to visit houses,
                irrespective of caste or creed to proclaim the gospel and for
                Bible study and intercession.
              </p>

              <p>
                Every year the ‘World Day of Prayer' is observed during the
                first Friday in March. The first Sunday in March is observed as
                the Women’s Sunday. We have the Annual Conference of the WF
                usually during the month of May. Besides we have the fellowship
                of the least coin also. Both men and youngsters encourage the WF
                with their ardent support. God is sustaining the WF through
                thick and thin for the last 64 years.
              </p>

              <h6>Magazine:</h6>
              <p>
                Kudumbapriyavadini: the newsletter of Women’s Fellowship,
                publishes articles on biblical study, family, health, mental
                health, women’s problems, privileges and duties, Christian
                education, Christian values and many other matters.
              </p>

              <h6>Projects</h6>
              <p>
                • Jewel Box: Jewel Box is a restaurant run by the WF. This is
                situated in the Baker Compound.
              </p>
              <p>• Pisgah: A Home for retired single women.</p>
              <p>
                • Babyland Creche/ Day Care Centre: More than 20 children
                between the age of one and a half to three and a half are being
                looked after during the daytime.
              </p>
              <p>• Agape: Wafer used for communion is being made here.</p>
              <p>
                • Anil Memorial Home for the Aged: Dr Mary George presented her
                Bungalow at Mavelikara to WF to convert it into an Old Age Home
                in memory of her son Anil.
              </p>
              <p>
                • Tabitha Tailoring Centre: Surplice, Stoles, Choir uniforms and
                other church vestments are made here.
              </p>
              <p>
                • Asha Bhavan Respite Care Centre: Asha Bhavan is for Mentally
                and Physically challenged girls. This centre is at Kanakkary,
                near Ettumanoor.
              </p>

              <h6>Diocesan Child Care Ministry</h6>
              <p>
                The Child Care Ministry of CSI Diocese of Madhya Kerala is
                working under the Children’s Welfare Board with a vision to
                uplift the poor and the unfortunate children of Madhya Kerala.
                The Program focuses on helping and sustaining the poor and the
                underprivileged children in our economically backward society
                and also their families to have self-esteem and creditability of
                their life. Now the Child Care Ministry supports 657 children
                irrespective of caste and creed who are in various Day Care
                Centres, Child Development Care Projects and Residential
                Projects. The Children who had been in this project has come up
                in life and attained creditable status in society. Still, there
                are many children deserving support for their development.
              </p>
              <h3 className="about-subtitle mt-5">
                Diocesan Mission Fields and Mission Institutions
              </h3>
              <p>
                Parkal Mission (started in 1924): Missionary Rev. Rijin P.
                Abraham 0967648241633
              </p>
              <p>
                Telangana Mission (Mugalappally Mission) (started in 1967):
                Missionary Rev. Jess Mathew 08179993207
              </p>
              <p>
                Charkhari Mission (started in 1982): Missionary Rev. Jerin
                Thomas George 08086258202
              </p>
              <p>
                Bhilai Industrial Mission (started in 1988): Missionary Rev.
                Subin G. Thomas 08602164610
              </p>
              <p>
                Mysore Mission (started in 1995): Missionary Rev. Anoop George
                Joseph 08939179960
              </p>
              <p>
                Vellore Mission (started in 2000): Missionary Rev. Vijo T.
                Joseph 09946888560
              </p>
              <p>
                Uttarakhand Mission (started in 2005): Missionary-in-charge Rev.
                Mathew Mathew 09544410348, Missionary Mr Soby George 07895807003
              </p>
              <p>
                Odisha Mission (started in 2014): Rev. Soji A. Thomas 8281640620
              </p>
              <p>
                Palani Hills Mission (started in 2014): Rev. Aneesh M. Philip
                07094777794
              </p>
              <p>
                Shalom Mission (started in 2014): Rev. Abraham G. Sathyanathan
                9961572105
              </p>
              <p>
                Bengal Mission (started in 2015): Rev. Mathew Daniel 08281067515
              </p>
              <p>
                Katrapally Mission (started in 2017): Missionary Rev. Prethesh
                Babu Kurian M. 08106373978
              </p>
              <p>Nepal Mission (started in 2017)</p>
              <p>
                Jhabua Mission (started in 2019): Missionary in Charge Rev. Biju
                Kunjumman 09745268311
              </p>
              <p>
                Thottapradesa Mission (started in 1988): Missionaries Rev. Shibu
                Selvaraj 09746935610
              </p>
              <p>
                Kuttanadu Mission (started in 2007): Missionary Rev. Nibu Thomas
                9645561492
              </p>
              <p>
                Malayora Mission (started in 2011): Missionary Rev. Sabin Jo
                Benson 9747099278
              </p>
              <h3 className="about-subtitle mt-5">
                Institutions under the Diocese
              </h3>
              <p>
                <b>Ashram</b>
              </p>
              <p>Bethel Ashram, Thiruvalla</p>

              <p>
                <b>Printing Press</b>
              </p>
              <p>
                C.M.S. Press, Kottayam (The first printing press in Kerala,
                established in 1821)
              </p>

              <p>
                <b>Colleges</b>
              </p>
              <p>
                CMS College Kottayam (The first college in South India,
                established in 1817)
              </p>
              <p>Peet Memorial Training College, Mavelikara</p>
              <p>Bishop Moore College, Mavelikara</p>
              <p>Bishop Speechly College, Pallom</p>
              <p>CSI College for Legal Studies, Ettumanoor</p>
              <p>Baker Women's College, Kottayam</p>

              <p>
                <b>Higher Secondary Schools</b>
              </p>
              <p>Baker Memorial Girls Higher Secondary School, Kottayam</p>
              <p>Bishop Hodges Higher Secondary School, Mavelikara</p>
              <p>CMS Higher Secondary School, Mallapally</p>
              <p>CMS College Higher Secondary, Kottayam</p>
              <p>CMS Higher Secondary School,&nbsp;Kuzhikala</p>

              <p>
                <b>High Schools</b>
              </p>
              <p>CMS College Higher Secondary School Kottayam</p>
              <p>Baker Memorial Girls High School, Kottayam</p>
              <p>Bishop Hodges Higher Secondary School, Mavelikara</p>
              <p>CMS Higher Secondary School, Mallapally</p>
              <p>CMS Higher Secondary, Kuzhikala</p>
              <p>Buchanan Girls High School, Pallom</p>
              <p>CMS High School, Mundakayam</p>
              <p>CMS High School, Kattanam</p>
              <p>CMS High School, Kumplampoika</p>
              <p>CMS High School, Nedugadapally</p>
              <p>CMS High School, Pallom</p>
              <p>CMS High School, Punnavely</p>
              <p>CMS High School, Olassa</p>
              <p>CMS High School, Puthupally</p>
              <p>CMS High School, Kanam</p>
              <p>CMS High School, Thiruvalla</p>
              <p>CMS High School, Thalavady</p>
              <p>CMS High School, Mundiapally</p>

              <p>
                <b>Upper Primary Schools</b>
              </p>
              <p>CMS UPS Athirunkal</p>
              <p>CMS UPS Chelakompu</p>
              <p>CMS UPS Karappuram Mission</p>
              <p>CMS UPS Kattampakkal</p>
              <p>CMS UPS Kodukulanji</p>
              <p>CMS UPS Kulathupuzha</p>
              <p>CMS UPS Nallanikunnu</p>
              <p>CMS UPS Punnackad</p>
              <p>CMS UPS Ranni</p>

              <p>
                <b>Kindergartens Schools</b>
              </p>
              <p>B M Kindergarten School, Kottayam 0481 2564180</p>
              <p>B M K Teacher Training School, Kottayam 9539529421</p>
              <p>Inter National Play School, Mavelikara 8289852052</p>

              <p>
                <b>Theological Education</b>
              </p>
              <p>Bishop Mani Theological Institute, Kottayam</p>

              <p>
                <b>Self-Financing Schools</b>
              </p>
              <p>Baker Vidyapith, Kottayam</p>
              <p>Bishop Moore Vidyapith, Mavelikara</p>
              <p>
                Bishop Moore English Medium Higher Secondary School, Mavelikara
              </p>
              <p>Bishop Moore Vidyapith, Cherthala</p>
              <p>Bishop Moore Vidyapith, Kayamkulam</p>
              <p>Bishop Speechly Vidyapith, Pallom</p>
              <p>Christ Church Vidyapith, Kodukulanji</p>
              <p>Hawksworth Vidyapith, Tholassery</p>

              <p>
                <b>Teacher Training College</b>
              </p>
              <p>Bishop Peet Memorial Training College, Mavelikkara.</p>

              <p>
                <b>Teacher Training Institutes</b>
              </p>
              <p>B.I.T.T.I, Pallom</p>
              <p>C.N.I.T.T.I, Kottayam</p>

              <p>
                <b>Special Schools</b>
              </p>
              <p>C.S.I VHSS for the Deaf, Thiruvalla</p>
              <p>C.S.I HSS for the Partially Hearing, Manakkala, Adoor</p>

              <p>
                <b>Training Institute</b>
              </p>
              <p>Industrial School, Kottayam</p>

              <p>
                <b>Weaving School</b>
              </p>
              <p>CMS Weaving School, Thukalassery, Thiruvalla</p>

              <p>
                <b>Hostels</b>
              </p>
              <p>Askwith Hostel for Men, (PG Hostel), CMS College Kottayam</p>
              <p>Baker School Boarding House</p>
              <p>Bishop Moore Vidyapith Boys' Hostel, Mavelikara</p>
              <p>Bishop Moore Vidyapith Girls' Hostel, Mavelikara</p>
              <p>Cathedral Hostel for Women, Kottayam</p>
              <p>CMS Industrial School Hostel, Kottayam</p>
              <p>Ella Hostel, CMS College PG Hostel</p>
              <p>Lea Hostel, CMS College, Kottayam</p>

              <p>
                <b>Hospitals</b>
              </p>
              <p>G.M.M. Hospital, Mallappally Ph. 0469 2782262/ 2785262</p>
              <p>B.J.M. Hospital, Pallom Ph. 0481 2432213</p>
              <p>Aliveu Palliative &amp; Home Care, Pallom Ph. 9061507084</p>

              <p>
                <b>Counselling Centre</b>
              </p>
              <p>CSI Counselling Centre, Kottayam</p>

              <p>
                <b>Book Depots</b>
              </p>
              <p>Diocesan Book Depot, Kottayam</p>
              <p>Diocesan Book Depot (Noah’s Arch), Adoor</p>

              <p>
                <b>Youth Centre</b>
              </p>
              <p>CSI Youth Centre, Changanacherry</p>

              <p>
                <b>Retreat Centres</b>
              </p>
              <p>CSI Retreat Centre, Kottayam</p>
              <p>Eco-Spirituality Centre, Othara</p>
              <p>Eco-Tourism Centre, Kumarakom</p>
              <p>Home Wood Eco-Spirituality Centre, Kodaikanal</p>
              <p>Kenly Eco-Spirituality Centre, Kodaikanal</p>

              <p>
                <b>Studio</b>
              </p>
              <p>Seraphim Studio, Kottayam</p>
              <h3 className="about-subtitle mt-5">Churches Abroad</h3>
              <p>
                <b>Canada</b>
              </p>
              <p>CSI Church, Toronto</p>

              <p>
                <b>UK</b>
              </p>
              <p>Church of South India (CSI) Malayalam Congregation, London</p>
              <p>ST Thomas CSI Church, Belfast</p>
              <p>CSI Congregation of Manchester, Midlands and Sheffield.</p>

              <p>
                <b>Kuwait</b>
              </p>
              <p>St. Peter's CSI Church</p>

              <p>
                <b>Qatar</b>
              </p>
              <p>CSI St.Thomas Church -Malayalam Congregation, Doha - Qatar</p>

              <p>
                <b>UAE</b>
              </p>
              <p>CSI Parish, Abu Dhabi</p>
              <p>CSI Parish, Dubai</p>
              <p>CSI Parish, Sharjah</p>
              <p>All Saints CSI Parish, Jebel Ali</p>

              <p>
                <b>USA</b>
              </p>
              <p>CSI Emmanuel Congregation, Atlanta</p>
              <p>CSI Congregation of Great Lakes, Michigan</p>
              <p>Emmanuel CSI Church, Philadelphia</p>
              <p>CSI Congregation of Dallas</p>
              <p>CSI Congregation of Hudson Valley</p>
              <p>
                Church of South India Malayalam Congregation of Greater New York
              </p>

              <p>
                <b>Australia</b>
              </p>
              <p>Holy Trinity CSI Church, Brisbane</p>

              <p>
                <b>Singapore</b>
              </p>
              <p>Immanuel Congregation (CSI), Singapore</p>

              <p>
                <b>Ireland</b>
              </p>
              <p>Holy Trinity CSI Congregation, Dublin</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CSIMKD;
